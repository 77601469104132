<template>
    <div class="container">
        <p class="title" style="font-size: 28px; margin-bottom: 10px;">创新工业园日新月异，宝德仕职工中秋发福利啦</p>
        <p class="time" style="font-size: 18px; margin-bottom: 20px;">2020-09-28 20:53</p>
        <p class="text">创新工业园是坪山区政府主力打造，以创新为主题的工业园区。今年园区的企业在政府的关怀下，即使在疫情如此严重，园区的企业克服了困难，获得了不错的成绩。</p>
        <img src="../../static/img/consultCenterImg/news-5/1.png" style="height: 30vw; display: block; margin: auto;" alt="">
        <p class="text" style="margin-bottom: 0px;">宝德仕电玩制造（深圳）有限公司于1985年创立于深圳市坪山区﹐现座落在坪山服产创新工业园区（具体座标：坪山区龙田街道竹坑社区金牛东路第三工业区C区7号楼）。公司在疫情期间不断创新、变革，寻求机遇，迎难而上、克服困难，得到市场的认可！现大量招聘员工，创造就业，为实现更多的岗位不断努力。</p>
        <div class="grid" style="grid-template-columns: 2fr 3fr; height: 30vw; grid-row-gap: 0;">
            <img src="../../static/img/consultCenterImg/news-5/2.png" style="height: 80%; display: block; margin: auto;" alt="">
            <img src="../../static/img/consultCenterImg/news-5/3.png" style="height: 80%; display: block; margin: auto;" alt="">
        </div>
        <p  class="text">中秋节将至，公司为让各职工过好一个幸福、祥和的传统佳节，为各职工准备了可口的、高品质的特供中秋月饼，及派发购物卡的福利。为国家实现内需大循环贡献绵薄之力！</p>
        <div class="grid" style="grid-template-rows: 1fr 1fr 1fr;">
            <img src="../../static/img/consultCenterImg/news-5/4.png" style="height: 80%; width: 80%; display: block; margin: auto;" alt="">
            <img src="../../static/img/consultCenterImg/news-5/5.png" style="height: 80%; width: 80%; display: block; margin: auto;" alt="">
            <img src="../../static/img/consultCenterImg/news-5/6.png" style="height: 80%; width: 80%; display: block; margin: auto;" alt="">
            <img src="../../static/img/consultCenterImg/news-5/7.png" style="height: 80%; width: 80%; display: block; margin: auto;" alt="">
            <img src="../../static/img/consultCenterImg/news-5/8.png" style="height: 80%; width: 80%; display: block; margin: auto;" alt="">
            <img src="../../static/img/consultCenterImg/news-5/9.png" style="height: 80%; width: 80%; display: block; margin: auto;" alt="">
        </div>
    </div>
</template>
<script>
export default {created() {
      this.isActive = this.$route.params.index;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0
  },
}
</script>
<style scoped>
    *{
        padding: 0;
        box-sizing: border-box;
        margin: 0;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: bold;
        color: #000000;
    }

    .title, .time{
        display: block;
        margin: auto;
        text-align: center;
    }

    .text{
        font-size: 16px;
        text-indent: 2em;
        margin-bottom: 20px;
        margin-top: 20px;
        text-align: center;
    }

    .grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 20px;
        width: 90%;
        margin: auto;
        margin-bottom: 25px;
        
    }
</style>